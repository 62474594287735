.page-dashboard{

    .ant-card-head{
        display: flex;
        justify-content: center;
    }
    .ant-card-head-title{
        padding: 0;
        font-weight: 700;
        text-transform: capitalize;
    }

    .widget{

        .ant-descriptions-item-content,
        .ant-descriptions-item-label {
            position: relative;
            padding: 10px 0px;
            color: #000000d9;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            background-color: #fafafa;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            transition: all .3s;
            align-items: center !important;
        }
    
        .ant-descriptions-item-label {
            width: 50%;
            justify-content: center;
        }
    
        .ant-descriptions-item-label::after {
            margin: 0;
        }
        .ant-descriptions-item-content{
            background-color: #fff;
            border-left: 0px solid #d9d9d9;
            text-align: center;
            justify-content: center;
        }
    
        .ant-descriptions-row > td {
            padding-bottom: 0;
        }

    }

    .widget.site-api-widgets{
        .ant-descriptions-item-label {
            width: 25%;
            justify-content: center;
        }
        .ant-descriptions-item-content {
            width: 75%;
            justify-content: start;
            padding-left: 10px;
        }
    }

}