.ant-row.page-company,
.ant-row.page-locations-edit,
.ant-row.page-user{
    
    h3{
        font-weight: 700;
    }
    .ant-tabs-nav-wrap{
        border-right: 1px solid rgb(240, 240, 240);
    }

}