.short-info-language{
    p{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        strong{
            text-transform: capitalize;
            width: 20%;
        }

        span.first-span{
            text-align: left;
            width: 80%;
            display: block;
        }
    }
}