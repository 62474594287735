.ant-layout-header {
    padding: 0 20px !important;
    background-color: #fff !important;
    border-color: #eee !important;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    height: 50px !important;
    align-items: center;
    line-height: 30px !important;

    a{
        color: #333 !important;
        font-size: 13px;
    }
}

.logo{

    .bold {
        font-weight: 700;
    }

    .navbar-brand {
        color: #333;
        font-size: 20px;
        font-weight: 300;
        line-height: 50px;
        text-align: center;
    }

}

.header{
    .ant-dropdown-trigger{
        cursor: pointer;
    }

    .my-profile-link,
    .real-time{
        font-size: 13px;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: none;
    }
}

