.app{

    main{
        display: flex;
        justify-content: center;
        flex: 1;
        width: 100%;
    }

    .container{
        max-width: 1024px;
        width: 100%;

        .row{
            & + .row{
                margin-top: 24px;
            }
        }

        .block{
             &.sml{
                 max-width: 450px;
                 min-width: 270px;
                 margin: 0 auto;
             }
        }


    }

}