.sidebar-left {
    background: #23272d !important;

    .ant-menu {
        color: #8f939e !important;
        background: #23272d !important;
    }

    .ant-menu-item a{
        color: #8f939e;
    }

    .sidebar-header {
        color: #6F737E;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        padding: 10px 20px 5px;
    }
    
    .ant-menu{

        &-submenu-selected,
        &-item-selected {
            color: #fff !important;
        }

    }

    .ant-menu-item a:hover,
    .ant-menu-item-selected a, 
    .ant-menu-item-selected a:hover {
        color: #fff;
    }


    .ant-menu-submenu-title:active,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #23272d !important;
    }

    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid #ff905c !important;
    }

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
        color: #fff !important;
    }

    .ant-menu-submenu-title:hover,
    .ant-menu-item:hover {
        color: #fff !important;
    }


    .ant-menu-submenu-title:active {
        background: #e6f7ff;
    }

}