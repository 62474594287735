$primary-font: 'Nunito', sans-serif;
$secondary-font: 'Nunito', monospace;
$primary-font-size: 16px;
$primary-line-height: 1.5em;

$background: #fff0e0;

$white: #ffffff;
$orange: #ff905c;
$orange-red: #ff5e2d;
$dark-green: #13444f;
$habr-bar: #4f5a6e;

$error: #ff0000;
$success: #35B40C;
$warning: #FFD306;