.copy-link{
    cursor: pointer;
}

.widget {
    
    button{
        width: auto;
        height: auto; border: 0;
    }

    .ant-typography{
        margin: 0;
    }
}