@import 'scss/vendors/include-media';


.form-holder{

    h1{
        margin-bottom: 30px;
    }

}

.forgot-password{
    text-align: left;

    .ant-btn{
        max-width: 320px;


        @include media('<=phone') {
            width: 100%;
            max-width: initial;
        }
    }

    .step-actions,
    .form-actions{
        display: flex;
        justify-content: flex-end;

        @include media('<=phone') {
            justify-content: center;
        }
    }
}

.forgot-password{
    .row{
        margin-top: 60px;
    }

    &-message{
        p{
            margin-top: 10px;

            &:first-child{
                margin-top: auto;
            }
        }

        a{
            margin-top: 20px;
            display: block;
        }
    }

    &.change{
        form{
            margin-top: 40px;
        }
    }
}

.sign-in-form{
    text-align: left;

    .form-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login-form-link{
        text-decoration: underline;

        &:hover{
            color: var(--active-text-color);
        }
    }
}